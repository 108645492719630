/*############## Responsive ################*/

@media (max-width: 1199.98px) {

	.header .navbar-brand {
		width: 200px;
	}

	.header .navigation-menu ul li a {
		font-size: 14px;
	}

	.header .navigation-menu ul li {
		margin: 0 8px;
	}

	.ms-3 {
		margin-left: 10px !important;
	}

	.ban-inr-sec .banner-txt h3 {
		font-size: 40px;
	}

	.footer .fnav-link li a {
		font-size: 13px;
	}

	.ban-inr-sec .banner-txt h4 {
		font-size: 30px;
	}

	.rt-count-prt .count-bx h3 {
		font-size: 25px;
	}

	.title-prt h2 {
		font-size: 34px;
	}

	.newsletter-prt .newsletter-bx {
		width: 70%;
	}

}

@media (max-width: 991.98px) {

	.header {
		position: static;
		padding: 10px 0;
	}

	.navbar {
		display: block;
	}

	.navbar .btn-outline-danger {
		position: absolute;
		right: 0;
		top: 0;
	}

	.menu-mobile {
		margin: 12px 0 0;
		background-color: rgba(0, 0, 0, .7);
		color: #fff;
	}

	.navigation-menu>ul {
		background-color: rgba(0, 0, 0, .9);
	}

	.header .navigation-menu ul li {
		margin: 0;
		background: transparent;
	}

	.header .navigation-menu ul li a {
		padding: 9px 15px;
		border: none;
		border-bottom: 1px solid;
		color: #fff;
	}

	.header .nav-dropdown {
		top: 0;
	}

	.nav-rt-prt ul li a {
		padding: 2px 0 !important;
		border-bottom: none !important;
		color: #020202 !important;
	}

	.menu-mobile:hover {
		color: #fff;
	}

	.ban-inr-sec .banner-txt {
		width: 45%;
	}

	.ban-inr-sec .banner-txt h3 {
		font-size: 35px;
	}

	.ban-inr-sec .banner-txt p {
		margin: 8px 0 0;
		font-size: 14px;
	}

	.about-sec .abt-rt-txt {
		padding: 20px;
	}

	.about-sec .abt-rt-txt h3 {
		font-size: 30px;
	}

	.about-sec .abt-rt-txt p {
		font-size: 15px;
	}

	#horizontalTab ul.resp-tabs-list {
		width: 100%;
	}

	#horizontalTab .resp-tabs-container {
		width: 100%;
	}

	h2.resp-tab-active {
		background: #010101 !important;
		color: #fff !important;
	}

	h2.resp-accordion {
		font-size: 16px;
		color: #B70F0A;
	}

	.calculation-prt ul li label {
		font-size: 13px;
	}

	.title-prt h2 {
		font-size: 30px;
	}

	.title-prt h2 br {
		display: none;
	}

	#verticalTab .resp-tabs-container {
		width: 100%;
	}

	#verticalTab h2.resp-accordion {
		color: #fff;
	}

	.main-title {
		font-size: 32px;
	}

	.newsletter-prt .newsletter-bx {
		width: 80%;
	}

	.mt-5 {
		margin-top: 2rem !important;
	}

	.footer .footer-list2 li {
		width: 50%;
		font-size: 14px;
		margin: 0 0 5px;
	}

	.footer .footer-list li {
		font-size: 14px;
		margin: 0 0 5px;
	}

	.footer .foot-mid .f-pera {
		width: 70%;
		font-size: 15px;
	}

	.ban-inr-sec {
		top: 50%;
	}

	.inr-ban {
		height: 280px;
	}

	.ban-inr-sec .banner-txt h4 {
		font-size: 26px;
		line-height: 38px;
	}

	.mv-section .mv-txt {
		padding: 30px;
	}

	.abt-txt p {
		line-height: 26px;
		font-size: 15px;
	}

	.ban-inr-sec .banner-txt p {
		margin: 0;
	}

	.inr-ban .banner-txt ul {
		margin: 0;
	}

	.inr-ban .banner-txt ul li {
		margin: 0 0 4px;
	}

	.abt-txt h3 {
		font-size: 32px;
	}

	.rt-count-prt {
		padding: 15px;
	}

	.contact-bx ul li {
		width: 100%;
	}

}

@media (max-width: 767.98px) {
	.navbar .btn-outline-danger {
		display: none;
	}

	.banner-inner-section img {
		height: 210px;
	}

	.p-0 {
		padding: 0 10px !important;
	}

	.ban-inr-sec .banner-txt {
		width: 80%;
	}

	.ban-inr-sec .banner-txt h3 {
		font-size: 20px;
	}

	.ban-inr-sec .banner-txt p {
		margin: 5px 0 0;
		font-size: 13px;
		line-height: 19px;
	}

	.ban-inr-sec .btn-danger {
		padding: 6px 12px !important;
		font-size: 13px;
	}

	.ban-inr-sec .btn-outline-danger {
		padding: 6px 12px !important;
		font-size: 13px;
	}

	.mt-3 {
		margin-top: 10px !important;
	}

	.banner-slider .owl-dots {
		left: inherit;
		right: 10px;
		flex-direction: column;
	}

	.banner-slider .owl-dots .owl-dot {
		margin: 4px 0;
	}

	.about-sec {
		display: block;
	}

	.about-sec .abt-img-prt {
		width: 100%;
	}

	.about-sec .abt-rt-txt {
		padding: 15px;
		width: 100%;
	}

	.about-sec .abt-rt-txt h3 {
		font-size: 26px;
	}

	.btn-light {
		font-size: 14px;
	}

	.btn-outline-light {
		font-size: 14px;
	}

	.d-flex {
		display: block !important;
	}

	.product-wrap {
		padding: 40px 0;
	}

	.pro-OverWidth {
		width: 100%;
	}

	.main-wrap {
		padding: 40px 0;
	}

	.main-title {
		font-size: 28px;
	}

	#horizontalTab ul.resp-tabs-list {
		display: none;
	}

	.calculator-form {
		display: block;
	}

	.calculator-form .form-group {
		width: 100%;
		margin: 8px 0;
	}

	.calculation-prt ul {
		display: block;
	}

	.calculation-prt ul li {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #C9C9C9;
		padding: 8px 0;
	}

	.title-prt h2 {
		font-size: 28px;
	}

	#verticalTab .resp-tabs-container .resp-tab-content {
		padding: 15px;
	}

	#verticalTab .resp-tabs-container .resp-tab-content .mobile-img {
		width: 50%;
		margin: 0 auto;
	}

	#verticalTab .resp-tabs-container .resp-tab-content .tab-txt {
		width: 100%;
		padding: 15px 0 0;
	}

	.btn-grp img {
		width: 45%;
	}

	.testi-wrap {
		padding: 40px 0;
	}

	.testi-bx {
		padding: 50px 15px 15px;
	}

	.newsletter-prt {
		padding: 20px;
	}

	.main-title {
		font-size: 21px;
	}

	.main-title span {
		font-size: 14px;
	}

	.newsletter-prt .newsletter-bx {
		width: 100%;
	}

	.newsletter-prt .newsletter-bx input[type="email"] {
		font-size: 14px;
		height: 48px;
		padding: 8px 88px 8px 10px;
	}

	.newsletter-prt .newsletter-bx .search-icon {
		font-size: 14px;
		padding: 12px 10px;
	}

	.footer .footer-title {
		margin: 15px 0;
	}

	.footer .footer-list2 li {
		width: 100%;
	}

	.footer .foot-mid {
		display: block;
	}

	.footer .foot-mid .f-pera {
		margin: 12px 0 0;
		width: 100%;
	}

	.footer .f-bttm .f-social {
		text-align: center;
		display: inline-block;
		width: 100%;
	}

	.footer .f-bttm .f-social li {
		float: none;
		display: inline-block;
	}

	.footer .f-bttm .copyright {
		text-align: center;
		margin: 12px 0 0;
	}

	.header .nav-dropdown .nav-img {
		display: none;
	}

	.header .nav-dropdown .nav-rt-prt {
		width: 100%;
		padding: 15px;
		display: block;
	}

	.header .nav-dropdown .nav-rt-prt ul {
		padding: 12px 0 0;
		width: 100%;
	}

	.modal-1 .modal-dialog .modal-content .modal-body {
		padding: 15px;
	}

	.modal .btn-close {
		right: 20px;
		color: #020202;
		z-index: 99999;
		border: 1px solid #020202;
		width: 30px;
		height: 30px;
	}

	.modal.show .btn-close {
		top: 26px;
	}

	.ban-inr-sec .banner-txt h4 {
		font-size: 20px;
		line-height: 32px;
	}

	.abt-txt h5 {
		font-size: 14px;
	}

	.abt-txt h3 {
		font-size: 30px;
	}

	.rt-count-prt {
		padding: 20px 0 0;
	}

	.mv-section {
		display: block;
	}

	.mv-section .mv-img {
		width: 100%;
	}

	.mv-section .mv-txt {
		padding: 15px;
		width: 100%;
	}

	.mt-4 {
		margin-top: 1rem !important;
	}

	.inr-ban .ban-inr-sec .banner-txt {
		width: 100%;
	}

	.inr-ban .banner-txt ul li {
		font-size: 14px;
	}

	.pdf-list li {
		display: block;
		padding: 10px 0;
	}

	.pdf-list li p {
		font-size: 16px;
		margin: 0 0 10px;
	}

	.pdf-list li .btn-outline-danger {
		padding: 6px 15px !important;
		font-size: 14px;
	}

	.contact-img {
		height: 350px;
		margin-bottom: 20px;
	}

	.msme-bx .table tr td {
		padding: 10px 0;
		font-size: 14px;
	}

	.blog-box2 .blog-dtl-txt {
		padding: 15px;
	}

	.category-box {
		margin: 15px 0 0;
	}

	.blog-box2 .blo-img {
		height: auto;
	}
}
