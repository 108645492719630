/* React-Slick - Core */
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
  }
  
  .slick-list {
	position: relative;
	/* overflow: hidden; */
	display: block;
	margin: 0;
	padding: 0;
  }
  
  .slick-track {
	position: relative;
	width: 200px;
	display: flex;
	flex-direction: row; /* Ensures slides align properly */
	top: 0;
	left: 0;
  }
  
  .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
  }
  
  .slick-slide img {
	display: block;
	width: 100%;
  }
  
  .slick-initialized .slick-slide {
	display: block;
  }
  
  .slick-dots {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .slick-dots li {
	position: relative;
	display: inline-block;
	margin: 0 5px;
	border-radius: 50%;
  }
  
  .slick-dots button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 4px;
	height: 4px;
	padding: 5px;
	cursor: pointer;
	border: 1px	solid #ccc;
	outline: none;
	background: #ccc;
	border-radius: 50%;
  }
  
  .slick-dots li.slick-active button {
	background: #fff;
	font-size:0;
  }
  
  /* Navigation Arrows */
  .slick-prev,
  .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	padding: 0;
	cursor: pointer;
	border: 2px solid #ccc;
	outline: none;
	background: transparent;
	color: #333;
	z-index: 1;
  }
  
  .slick-prev {
	left: -35px;
  }
  
  .slick-next {
	right: -35px;
  }
  .slick-dots li button:before{
	border: 2px solid #ccc;
	border-radius: 50%;
  }
  .slick-prev::before,
  .slick-next::before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	opacity: 0.75;
	color: #333;
	border: 2px solid #ccc;
  }
  
  .slick-prev::before {
	content: "←";
  }
  
  .slick-next::before {
	content: "→";
  }

  .pro-OverWidth {
	width: 100%;
  }
  
  .product-box {
/* 	width: 400px; */
	background-color: rgba(255, 255, 255, 0.03);
	padding: 20px;
	border-radius: 8px;
  margin: 0px 15px 0px 15px;
  }
  .product-box .pro-ico {
	display: flex;
	align-items: center;
	height: 80px;
  }
  .product-box .pro-ico img {
	max-height: 100%;
	width: auto;
  }
  .product-box h3 {
	font-size: 20px;
	font-weight: 600;
	color: #fff;
	margin: 60px 0 0;
	padding: 0;
  }
  .product-box p {
	color: #989898;
	font-size: 14px;
	margin: 12px 0 0;
  }
  .product-box a {
	display: inline-block;
	font-size: 16px;
	color: #fff;
	margin: 12px 0 0;
	text-decoration: none;
	transition: all ease-in-out 0.4s;
  }
  .product-box a:hover {
	color: #b70f0a;
  }
  
  .pro-slider .owl-dots {
	position: absolute;
	z-index: 9;
	text-align: center;
	bottom: -30px;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .pro-slider .owl-dots .owl-dot {
	width: 15px;
	height: 15px;
	background-color: transparent;
	border-radius: 50%;
	margin: 0 4px;
	padding: 2px;
  }
  .pro-slider .owl-dots .owl-dot span {
	border-radius: 50%;
	border: 1px solid #444444;
	display: block;
	width: 100%;
	height: 100%;
  }
  .pro-slider .owl-dots .owl-dot.active {
	border: 1px solid #444444;
  }
  .pro-slider .owl-dots .owl-dot.active span {
	background-color: #fff;
  }
  
  /* Custom Styling */
  .banner-slider {
	width: 100%;
	height: 500px;
	position: relative;
  }
  
  .banner-inner-section {
	text-align: center;
	padding: 20px;
  }
  
  .banner-txt {
	margin-top: 100px;
	/* height: 500px; */
  }
  
  .banner-txt h3 {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
  }
  
  .banner-txt p {
	font-size: 16px;
	color: #666;
  }
  
  .btn-grp {
	margin-top: 20px;
  }
  
  .btn {
	text-decoration: none;
	padding: 10px 20px;
	border-radius: 5px;
	display: inline-block;
  }
  
  .btn-danger {
	background-color: #dc3545;
	color: #fff;
  }
  
  .btn-outline-danger {
	background-color: transparent;
	border: 1px solid #dc3545;
	color: #dc3545;
  }