@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css?ver=1.0.0");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
.scale-up-center {
  animation: scale-up-center 0.9s ease-in both;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
body {
  font-family: "Roboto", sans-serif;
  color: #5c5c5c;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 24px;
  background: #fff;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: scroll;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;  
}

* {
  border: medium none;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none !important;
}

a:hover {
  text-decoration: none;
  outline: none;
}

textarea {
  resize: none;
}

p {
  line-height: 25px;
  font-size: 15px;
  font-weight: 300;
  color: #444444;
  margin: 0;
  letter-spacing: 1px;
}

/*#######################*/
.main-title {
  color: #18154e;
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-weight: 800;
  text-align: center;
}
.main-title span {
  font-size: 20px;
  font-weight: 400;
  color: #989898;
  display: block;
  text-transform: capitalize;
}

.main-pera {
  color: #000;
  text-align: center;
  margin: 15px 0 0;
}
.tab-grey{
 background: #18154e0d;
border-radius: 15px;
}
.btn-danger {
  color: #fff;
  background-color: #18154e !important;
  border-radius: 4px;
  border: none;
  padding: 8px 20px !important;
  font-weight: 400 !important;
  font-size: 15px;
  position: relative;
  overflow: hidden;
}
.btn-danger span {
  color: #fff;
  position: relative;
  transition: all ease-in-out 0.5s;
  z-index: 9;
}
.btn-danger:hover span {
  color: #fff !important;
}
.btn-danger:after {
  content: "";
  position: absolute;
  display: inline-block;
  transition: all ease-in-out 0.5s;
  background: #d40b15;
  bottom: -10%;
  left: 0px;
  width: 100%;
  height: 0%;
  border-radius: 50% 50% 0% 0%;
}
.btn-danger:hover::after {
  height: 200%;
}

.btn-light {
  color: #b70f0a;
  background-color: #fff !important;
  border-radius: 4px;
  border: none;
  padding: 8px 20px !important;
  font-weight: 400 !important;
  font-size: 15px;
  position: relative;
  overflow: hidden;
}
.btn-light span {
  color: #18154e;
  position: relative;
  transition: all ease-in-out 0.5s;
  z-index: 9;
}
.btn-light:hover span {
  color: #fff !important;
}
.btn-light:after {
  content: "";
  position: absolute;
  display: inline-block;
  transition: all ease-in-out 0.5s;
  background: #000;
  bottom: -10%;
  left: 0px;
  width: 100%;
  height: 0%;
  border-radius: 50% 50% 0% 0%;
}
.btn-light:hover::after {
  height: 200%;
}

.btn-outline-danger,
.btn-outline-danger:visited {
  color: #18154e;
  background-color: transparent !important;
  border-radius: 5px;
  border-color: #18154e;
  padding: 8px 20px !important;
  font-weight: 400 !important;
  font-size: 15px;
  transition: all ease-in-out 0.3s;
  position: relative;
  overflow: hidden;
}
.btn-outline-danger span {
  color: #18154e;
  position: relative;
  transition: all ease-in-out 0.5s;
  z-index: 9;
}
.btn-outline-danger:hover span {
  color: #fff !important;
}
.btn-outline-danger i {
  margin-right: 5px;
  position: relative;
  z-index: 9;
  transition: all ease-in-out 0.3s;
}
.btn-outline-danger:hover i {
  color: #fff !important;
}
.btn-outline-danger:after {
  content: "";
  position: absolute;
  display: inline-block;
  transition: all ease-in-out 0.5s;
  background: #b70f0a;
  bottom: -10%;
  left: 0px;
  width: 100%;
  height: 0%;
  border-radius: 50% 50% 0% 0%;
}
.btn-outline-danger:hover::after {
  height: 200%;
}

.btn-outline-light,
.btn-outline-light:visited {
  color: #fff;
  background-color: transparent !important;
  border-radius: 5px;
  border-color: #fff;
  padding: 8px 20px !important;
  font-weight: 400 !important;
  font-size: 15px;
  transition: all ease-in-out 0.3s;
  position: relative;
  overflow: hidden;
}
.btn-outline-light span {
  color: #fff;
  position: relative;
  transition: all ease-in-out 0.5s;
  z-index: 9;
}
.btn-outline-light:hover span {
  color: #18154e !important;
}
.btn-outline-light i {
  margin-right: 5px;
  position: relative;
  z-index: 9;
  transition: all ease-in-out 0.3s;
}
.btn-outline-light:hover i {
  color: #18154e !important;
}
.btn-outline-light:after {
  content: "";
  position: absolute;
  display: inline-block;
  transition: all ease-in-out 0.5s;
  background: #fff;
  bottom: -10%;
  left: 0px;
  width: 100%;
  height: 0%;
  border-radius: 50% 50% 0% 0%;
}
.btn-outline-light:hover::after {
  height: 200%;
}

.header {
/*  position: relative;*/
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 9;
  top: 0;
  padding: 2px 0;
  /* Navigation Dropdown Menu --------------------------------*/
}
.header .navbar-brand {
  width: 225px;
  margin: 0;
}
.header .navigation-menu ul li {
  margin: 0 10px;
  text-transform: capitalize;
}
.header .navigation-menu ul li a {
  color: #444444;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-weight: 500;
  padding: 11px 3px;
  transition: all ease-in-out 0.4s;
font-family: "Roboto";
}
.header .navigation-menu ul li:hover > a,
.header .navigation-menu ul li:focus > a,
.header .navigation-menu ul li.active > a,
.header .navigation-menu ul li a[aria-current="page"] {
  color: #18154e;
}
.header .nav-dropdown {
  width: 11%;
  padding: 0;
  left: 596px;
  top: 57px;
  margin: 0 auto;
  border: 1px solid #eee;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.08);
  border-top: 3px solid #b70f0a;
}
.header .nav-dropdown:after {
  display: none;
}
.header .nav-dropdown .nav-img {
  width: 33.33%;
}
.header .nav-dropdown .nav-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.header .nav-dropdown .nav-rt-prt {
  width: 66.66%;
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.header .nav-dropdown .nav-rt-prt ul {
  padding: 20px 0 0;
  margin: 0;
  border-top: 2px solid #b70f0a;
  width: 48%;
}
.header .nav-dropdown .nav-rt-prt ul li {
  font-size: 15px;
  color: #444444;
  margin: 0 0 10px;
  float: none;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  transition: all ease-in-out 0.5s;
}
.header .nav-dropdown .nav-rt-prt ul li:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #b70f0a;
  left: 0;
  transition: all ease-in-out 0.5s;
}
.header .nav-dropdown .nav-rt-prt ul li:hover {
  padding-left: 20px;
}
.header .nav-dropdown .nav-rt-prt ul li:hover:before {
  width: 15px;
}
.header .dropworn-arrow {
  top: 2px;
}
/* .header .navigation-menu ul li.menu-dropdown-icon:hover .nav-dropdown {
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s both;
}
.header .navigation-menu ul li.menu-dropdown-icon:hover .dropworn-arrow {
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s both;
} */

.banner-inner-section {
  position: relative;
}
.banner-inner-section img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.ban-inr-sec {
  position: absolute;
  left: 0;
  right: 0;
  top: 54%;
  transform: translateY(-50%);
  z-index: 1;
}
.ban-inr-sec .banner-txt {
  width: 45%;
}
.ban-inr-sec .banner-txt h3 {
  font-size: 30px;
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 0;
}
.ban-inr-sec .banner-txt h4 {
  font-size: 33px;
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 0;
  line-height: 44px;
}
.ban-inr-sec .banner-txt p {
  margin: 12px 0 0;
  font-weight: 300;
}

.banner-slider .owl-item.active .banner-txt h3 {
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

.banner-slider .owl-item.active .banner-txt p {
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s both;
}

.banner-slider .owl-item.active .banner-txt .btn-grp {
  animation: slide-top 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2s both;
}
@keyframes slide-top {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.banner-slider .owl-dots {
  position: absolute;
  z-index: 9;
  text-align: center;
  bottom: 18px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-slider .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 4px;
  padding: 2px;
}
.banner-slider .owl-dots .owl-dot span {
  border-radius: 50%;
  border: 1px solid #444444;
  display: block;
  width: 100%;
  height: 100%;
}
.banner-slider .owl-dots .owl-dot.active {
  border: 1px solid #444444;
}
.banner-slider .owl-dots .owl-dot.active span {
  background-color: #444444;
}

.about-sec {
  display: flex;
  align-items: stretch;
}
.about-sec .abt-img-prt {
  width: 50%;
}
.about-sec .abt-img-prt img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.about-sec .abt-rt-txt {
  width: 50%;
  background-color: #18154e;
  padding: 50px 120px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-sec .abt-rt-txt h5 {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  margin: 0;
  padding: 0;
}
.about-sec .abt-rt-txt h3 {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  padding: 0;
}
.about-sec .abt-rt-txt p {
  margin: 12px 0 0;
  color: #fff;
}

.product-wrap {
  padding: 60px 0;
  background: #0e0c2b url(../images/bg2.png) repeat;
/*  overflow: hidden;*/
}
.product-wrap .btn-outline-danger span {
  color: #fff;
}

.title-prt h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0;
}
.title-prt h3 {
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 21px 0px;
}
.title-prt h2 {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  margin: 0;
  padding: 0;
}

.pro-OverWidth {
  width: 100%;
}

.product-box {
  background-color: rgba(255, 255, 255, 0.03);
  padding: 20px;
  border-radius: 8px;
}
.product-box .pro-ico {
  display: flex;
  align-items: center;
  height: 80px;
}
.product-box .pro-ico img {
  max-height: 100%;
  width: auto;
}
.product-box h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin: 36px 0 0;
  padding: 0;
}
.product-box p {
  color: #989898;
  font-size: 15px;
  margin: 12px 0 0;
}
.product-box a {
  display: inline-block;
  font-size: 15px;
  color: #fff;
  margin: 12px 0 0;
  transition: all ease-in-out 0.4s;
}
.product-box a:hover {
  color: #b70f0a;
}

.pro-slider .owl-dots {
  position: absolute;
  z-index: 9;
  text-align: center;
  bottom: -30px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pro-slider .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 4px;
  padding: 2px;
}
.pro-slider .owl-dots .owl-dot span {
  border-radius: 50%;
  border: 1px solid #444444;
  display: block;
  width: 100%;
  height: 100%;
}
.pro-slider .owl-dots .owl-dot.active {
  border: 1px solid #444444;
}
.pro-slider .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.main-wrap {
  padding: 60px 0;
}

h2.resp-accordion {
  color: #217db8;
}

.description-sec .resp-tabs-container {
  background-color: transparent;
  clear: right;
}

#horizontalTab ul.resp-tabs-list {
  position: relative;
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  justify-content: center;
}
#horizontalTab ul.resp-tabs-list li {
  font-weight: 400;
  font-size: 20px;
  display: inline-block;
  padding: 9px 0;
  margin: 0 25px !important;
  float: none !important;
  color: #989898;
  border: none;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background-color: transparent;
}
#horizontalTab ul.resp-tabs-list li.resp-tab-active {
  color: #18154e !important;
  background-color: transparent !important;
  padding: 9px 0 !important;
  font-weight: 800 !important;
  border-bottom: 2px solid #b70f0a !important;
}
#horizontalTab .description-sec .resp-tab-content {
  border: none;
}
#horizontalTab .resp-tabs-container {
  background-color: #18154e12;
  margin-top: 20px;
  width: 90%;
  margin: 20px auto 0;
	padding: 25px;
border-radius: 15px;
}
#horizontalTab .resp-tabs-container .resp-tab-content {
  padding: 20px;
  border: none;
  border-radius: 7px;
  background-color: #18154e0d;
}

.calculator-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calculator-form .form-group {
  width: 24%;
  margin: 0;
}
.calculator-form .form-group .form-control {
  border-radius: 0;
  background-color: #fff;
  height: 40px;
  color: #000;
  font-size: 15px;
  border: none;
}
.calculator-form .form-group .form-select {
  border-radius: 0;
  background-color: #fff;
  height: 40px;
  color: #000;
  font-size: 15px;
  border: none;
}

.calculation-prt {
  margin: 15px 0 0;
}
.calculation-prt ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calculation-prt ul li {
  width: 22%;
  border-right: 1px solid #c9c9c9;
}
.calculation-prt ul li label {
  font-size: 15px;
  color: #989898;
  font-weight: 400;
}
.calculation-prt ul li h4 {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin: 8px 0 0;
  padding: 0;
}

.information-sec {
  background: #18154e url(../images/bg1.png) repeat;
  padding: 60px 0;
}
.information-sec .title-prt h5 {
  color: #fff;
}
.information-sec .title-prt h3 {
  color: #fff;
}

#verticalTab {
  display: flex !important;
  align-items: stretch;
}
#verticalTab .resp-tabs-list {
  background-color: transparent;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  width: 25%;
  border: 1px solid #fff;
  margin: 0 30px 0 0;
}
#verticalTab .resp-tabs-list li {
  border: none;
  background-color: transparent;
  margin-bottom: 0 !important;
  padding: 15px 15px !important;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  position: relative;
}
#verticalTab .resp-tabs-list li.resp-tab-active {
  background-color: #fff;
  padding: 20px 15px !important;
  color: #000;
  font-weight: 600;
}
#verticalTab .resp-tabs-container {
  padding: 0px;
  background-color: rgba(255, 255, 255, 0.14);
  border: none;
  width: 68%;
  min-height: 250px;
  border-radius: 10px;
  clear: none;
}
#verticalTab .resp-tabs-container .resp-tab-content {
  padding: 0;
  position: relative;
}
#verticalTab .resp-tabs-container .resp-tab-content .tab-txt {
  width: 60%;
  padding: 25px 0 25px 25px;
}
#verticalTab .resp-tabs-container .resp-tab-content .tab-txt h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding: 0;
/*  line-height: 32px;*/
}
#verticalTab .resp-tabs-container .resp-tab-content .tab-txt p {
  color: #fff;
  margin: 15px 0 0;
}
#verticalTab .resp-tabs-container .resp-tab-content .mobile-img {
  width: 170px;
}

.testi-wrap {
  padding: 60px 0;
  background: url(../images/bg3.png) repeat;
}
.testi-wrap .title-prt h5 {
  color: #000;
}
.testi-wrap .title-prt h2 {
  color: #000;
}

.testi-bx {
  border-radius: 15px;
  background-color: #fff;
  padding: 50px 25px 25px;
  position: relative;
}
.testi-bx:before {
  content: "";
  position: absolute;
  left: 22px;
  top: 20px;
  background: url(../images/quote.png) no-repeat;
  width: 26px;
  height: 24px;
}
.testi-bx p {
  color: #000;
  margin: 0;
}
.testi-bx h4 {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin: 15px 0 0;
  padding: 0;
  line-height: 26px;
}
.testi-bx h4 span {
  font-size: 16px;
  font-weight: 400;
  color: #989898;
  display: block;
}

.slide .owl-dots {
  position: absolute;
  z-index: 9;
  text-align: center;
  bottom: -55px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slide .owl-dots .owl-dot {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 50%;
  margin: 0 4px;
  padding: 2px;
}
.slide .owl-dots .owl-dot span {
  border-radius: 50%;
  border: 1px solid #444444;
  display: block;
  width: 100%;
  height: 100%;
}
.slide .owl-dots .owl-dot.active {
  border: 1px solid #444444;
}
.slide .owl-dots .owl-dot.active span {
  background-color: #000;
}

.footer {
  padding: 60px 0 0;
}
.footer .footer-title {
  color: #18154e;
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 18px;
  padding: 0;
}
.footer .footer-list {
  margin: 0;
  padding: 0;
}
.footer .footer-list li {
  float: none;
  font-size: 12px;
  margin: 0 0 0px;
  font-weight: 400;
  padding: 0;
}
.footer .footer-list li a {
  color: #989898;
}
.footer-list li a[aria-current="page"] {
  color: #d40000;
}
.footer .footer-list2 {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.footer .footer-list2 li {
  float: left;
  width: 33.33%;
  font-size: 12px;
  margin: 0 0 0px;
  font-weight: 400;
  padding: 0;
}
.footer .footer-list2 li a {
  color: #989898;
}
.footer .fnav-link {
  margin: 0;
  padding: 0;
}
.footer .fnav-link li {
  float: none;
  margin-bottom: 0px;
}
.footer .fnav-link li a {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  overflow: hidden;
  margin: 0;
  transition: all ease-in-out 0.3s;
}
.footer .fnav-link li a:hover {
  color: #b70f0a;
}
.footer .foot-mid {
  padding: 15px 0;
  margin: 30px 0 0;
  border-top: 1px solid #c9c9c9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .foot-mid .f-logo {
  width: 200px;
}
.footer .foot-mid .f-pera {
  color: #18154e;
  width: 60%;
}
.footer .f-bttm {
  background-color: #18154e;
  padding: 15px 0;
}
.footer .f-bttm .f-social {
  margin: 0;
  padding: 0;
}
.footer .f-bttm .f-social li {
  float: left;
  margin: 0 7px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  transition: all ease-in-out 0.3s;
}
.footer .f-bttm .f-social li i {
  font-size: 15px;
  color: #fff;
  line-height: 32px;
}
.footer .f-bttm .f-social li:hover {
  background-color: #b70f0a;
}
.footer .f-bttm .copyright {
  color: #989898;
  font-size: 14px;
  font-weight: 300;
}

.newsletter-prt {
  background: #18154e url(../images/bg1.png) no-repeat;
  padding: 45px;
  border-radius: 12px;
  text-align: center;
}
.newsletter-prt .newsletter-bx {
  position: relative;
  margin: 0 auto;
  width: 50%;
}
.newsletter-prt .newsletter-bx input[type="email"] {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border: none;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  height: 52px;
  line-height: 1;
  margin: 0;
  outline: medium none;
  padding: 8px 132px 8px 15px;
  width: 100%;
  border-radius: 6px;
}
.newsletter-prt .newsletter-bx input[type="email"]::placeholder {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
}
.newsletter-prt .newsletter-bx .search-icon {
  font-size: 15px;
  font-weight: 400;
  padding: 14px 32px;
  color: #18154e;
  background-color: #fff;
  display: inline-block;
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 6px 6px 0;
}

.clr-wht {
  color: #fff;
}
.clr-wht span {
  color: #fff;
}

.inr-ban {
/*  position: relative;*/
  height: 250px;
  background-color: #18154e;
  background-image: url('../images/breadcrumb-dot.png');
}
.inr-ban .banner-txt h4 {
font-size: 50px;
}
.inr-ban img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.inr-ban .banner-txt p {
  margin: 6px 0 0;
}
.inr-ban .banner-txt ul {
  margin: 8px 0 0;
  padding: 0;
}
.inr-ban .banner-txt ul li {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
}
.inr-ban .banner-txt ul li span {
  color: #b70f0a;
  margin-right: 4px;
}

.abt-txt h5 {
  font-size: 20px;
  font-weight: 300;
  color: #000;
  margin: 0;
  padding: 0;
}
.abt-txt h3 {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  margin: 0;
  padding: 0;
}
.abt-txt h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 25px 0 0;
  padding: 0;
}
.abt-txt p {
  margin: 12px 0 0;
  color: #000;
/*  font-weight: 100;*/
  line-height: 30px;
/*  letter-spacing: 0.1px;*/
}
.abt-txt ul {
  margin: 15px 0 0;
  padding: 0;
}
.abt-txt ul li {
  position: relative;
  font-size: 15px;
  font-weight: 400;
  color: #000;
  padding-left: 25px;
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}
.abt-txt ul li::before {
  position: absolute;
  content: "\f271";
  font-family: bootstrap-icons !important;
  font-size: 15px;
  color: #bb2727;
  left: 0;
}
.abt-txt ul.doc-list li:before {
  content: "\f38b";
  font-family: bootstrap-icons !important;
}

.rt-count-prt {
  background-color: #f2f1ff;
  padding: 30px;
  border-radius: 12px;
}
.rt-count-prt .count-bx {
  margin-bottom: 20px;
}
.rt-count-prt .count-bx h3 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 0 0 12px;
  position: relative;
}
.rt-count-prt .count-bx h3:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background-color: #bb2727;
}
.rt-count-prt .count-bx p {
  font-size: 15px;
  color: #000;
  margin: 12px 0 0;
}
.rt-count-prt .count-bx:last-child {
  margin: 0;
}

.mv-section {
  display: flex;
  align-items: stretch;
}
.mv-section .mv-img {
  width: 35%;
}
.mv-section .mv-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.mv-section .mv-txt {
  width: 65%;
  background-color: #18154e;
  padding: 50px 120px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.mv-section .mv-txt h4 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin: 0 0 15px;
  padding: 0;
}
.mv-section .mv-txt p {
  color: #c9c9c9;
  margin-bottom: 15px;
}

.clr-blk h5 {
  color: #000;
}
.clr-blk h2 {
  color: #000;
}

.team-bx .team-img {
  border-radius: 6px;
  overflow: hidden;
  height: 250px;
}
.team-bx .team-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.team-bx h4 {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  margin: 15px 0 0;
  padding: 0;
}
.team-bx h4 span {
  display: block;
  font-size: 15px;
  color: #989898;
}
.team-bx{
	text-align: center;
}
.inr-hdr {
  position: static;
  background: url(../images/bg3.png) repeat;
}

.contact-img {
  height: 100%;
  overflow: hidden;
}
.contact-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.form1 {
  margin: 15px 0 0;
}
.form1 .form-group {
  margin-bottom: 12px;
}
.form1 .form-group .form-control {
  background-color: transparent;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  height: 40px;
  font-weight: 300;
  color: #020202 !important;
}
.form1 .form-group textarea.form-control {
  height: 120px;
}

.address-title {
  font-size: 20px;
  font-weight: 400;
  color: #989898;
  margin: 0;
  padding: 8px 0;
  position: relative;
}
.address-title:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 35px;
  height: 3px;
  background-color: #b70f0a;
}

.f-contact {
  margin: 10px 0 0;
  padding: 0;
  display: inline-grid;
}
.f-contact li {
  float: none;
  margin-bottom: 12px;
  display: flex;
  align-items: baseline;
}
.f-contact li i {
  color: #18154e;
  margin-right: 6px;
  font-size: 15px;
  width: 26px;
}
.f-contact li p {
  color: #000;
  margin: 0;
  font-weight: 300;
  transition: all ease-in-out 0.3s;
  font-size: 12px;
}
.f-contact li a {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  transition: all ease-in-out 0.3s;
}
.f-contact li:hover a {
  color: #b70f0a;
}

.contact-bx {
  padding-bottom: 15px;
  margin-bottom: 25px;
  border-bottom: 1px solid #e8d5d5;
}
.contact-bx ul li {
  float: left;
  width: 100%;
}
.contact-bx ul li:first-child {
  width: 100%;
}
.contact-bx:last-child {
  border: none;
  padding: 0;
  margin: 0;
}

.media-bx .media-img {
  overflow: hidden;
  border-radius: 8px;
  height: 270px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}
.media-bx .media-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.media-bx .media-img .play-btn {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.media-bx .media-img .play-btn i {
  font-size: 52px;
  color: #b70f0a;
}
.media-bx .date {
  font-size: 15px;
  font-weight: 400;
  color: #989898;
  margin: 8px 0 0;
  display: block;
}
.media-bx h4 {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin: 8px 0 0;
  padding: 0;
  height: 43px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.media-bx a {
  font-size: 17px;
  font-weight: 500;
  color: #b70f0a;
  display: inline-block;
}

.press-wrap {
  background-color: #fff9f9;
  padding: 55px 0;
}

.press-bx {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e8d5d5;
}
.press-bx h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 6px 0 0;
  padding: 0;
}
.press-bx p {
  color: #444444;
  margin: 8px 0 0;
}
.press-bx a {
  font-size: 17px;
  font-weight: 500;
  color: #b70f0a;
  display: inline-block;
}

.quick-bx {
  margin-bottom: 20px;
}
.quick-bx .q-ico {
  display: flex;
  align-items: center;
  height: 35px;
}
.quick-bx .q-ico img {
  max-height: 100%;
}
.quick-bx h4 {
  font-size: 20px !important;
  font-weight: 600;
  color: #fff;
  margin: 8px 0 0 !important;
  padding: 0;
}
.quick-bx p {
  color: #fff;
  font-size: 15px;
  margin: 6px 0 0;
}

.msme-bx h3 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  margin: 0;
  padding: 10px 0 0;
  position: relative;
}
.msme-bx h3:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 35px;
  height: 4px;
  background-color: #18154e;
}
.msme-bx p {
  color: #727070;
  font-size: 15px;
  line-height: 27px;
  margin: 8px 0 0;
}
.msme-bx .table tr td {
  padding: 15px 0;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.msme-bx .benifit-bx {
  margin: 20px 0 0;
}
.msme-bx .benifit-bx h4 {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.msme-bx .benifit-bx h4 span {
  color: #bb2727;
  margin-right: 4px;
}

.pdf-list {
  margin: 0;
  padding: 0;
}
.pdf-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e3e3e3;
}
.pdf-list li p {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 0;
}
.pdf-list li p img {
  margin-right: 8px;
}

/* ######### Apply Now Popup ########## */
.modal .btn-close {
  position: absolute;
  top: 0;
  right: 50px;
  color: #fff;
  z-index: 9;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  background: transparent;
  border: 1px solid #fff;
  opacity: 0;
  transition: 0.5s;
  padding: 0;
}

.modal.show .btn-close {
  transition-delay: 0.7s;
  opacity: 1;
  top: 50px;
}

.modal::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  transform: scale(0.04) translateY(9999px);
  overflow: hidden;
  z-index: 9;
}

.modal-1 .modal-dialog {
  max-width: 400px;
  z-index: 9999;
  top: 0 !important;
  transform: translateY(-50%);
  transition: all 0.55s ease, opacity 0.35s ease-in;
  opacity: 0;
  z-index: 9999;
}
.modal-1 .modal-dialog .modal-content {
  border-radius: 12px;
}
.modal-1 .modal-dialog .modal-content .modal-body {
  padding: 25px;
}
.modal-1 .modal-dialog .modal-content .modal-body .form1 label {
  font-size: 15px;
  color: #444444;
  margin-bottom: 4px;
}
.modal-1 .modal-dialog .modal-content .modal-body .form1 p {
  color: #444444;
  font-size: 15px;
}
.modal-1 .modal-dialog .modal-content .modal-body .form1 p span {
  color: #b70f0a;
}

.modal-1.fade.show .modal-dialog {
  transform: translateY(0);
  transition-delay: 0.7s;
  opacity: 1;
  top: 50% !important;
  transform: translateY(-50%);
}

.modal-1.fade .modal-dialog {
  transition-delay: 0.7s;
  transition: opacity 0.7s;
}

.modal-1.fade.show::before {
  animation: menu-animation 0.8s ease-out forwards;
  transform: scale(2) translateY(0);
}

.modal-open .modal-1 {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%);
  }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px);
  }
  61% {
    opacity: 1;
    transform: scale(0.04) translateY(0px);
  }
  99% {
    opacity: 1;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    opacity: 1;
    transform: scale(2) translateY(0px);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}
/*######### Blog-details ##########*/
.blog-box2 {
  margin-bottom: 20px;
  border-radius: 10px;
}
.blog-box2 .blo-img {
  height: 350px;
  overflow: hidden;
}
.blog-box2 .blo-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-box2 .blog-dtl-txt {
  padding: 25px;
  border: 1px solid #ccc;
}
.blog-box2 .blog-dtl-txt img {
  margin: 12px 0;
}
.blog-box2 .blog-dtl-txt .date-pagi {
  margin: 0;
  padding: 0;
  display: inline-flex;
}
.blog-box2 .blog-dtl-txt .date-pagi li {
  float: left;
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  font-size: 15px;
  font-weight: 300;
  color: #000;
  border-right: 1px solid #d7d7d7;
}
.blog-box2 .blog-dtl-txt .date-pagi li i {
  margin-right: 8px;
  color: #b70f0a;
}
.blog-box2 .blog-dtl-txt h3 {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  margin: 10px 0 0;
  padding: 0;
}
.blog-box2 .blog-dtl-txt h4 {
  font-size: 18px;
  color: #b70f0a;
  margin: 15px 0;
  padding: 0;
}
.blog-box2 .blog-dtl-txt p {
  font-size: 20px;
  color: #525252;
  margin: 15px 0 0;
  line-height: 30px;
}

.blog-footr-prt {
  margin: 15px 0 0;
}
.blog-footr-prt .blog-social {
  margin: 0;
  padding: 0;
  display: inline-block;
}
.blog-footr-prt .blog-social li {
  float: left;
  margin-right: 15px;
  font-size: 17px;
}
.blog-footr-prt .blog-social li i {
  color: #a0a0a0;
  transition: all ease-in-out 0.3s;
}
.blog-footr-prt .blog-social li i:hover {
  color: #b70f0a;
}

.category-box h3 {
  font-size: 18px;
  color: #b70f0a;
  font-weight: 600;
  text-transform: uppercase;
  padding: 8px 12px;
  margin: 0;
  background-color: #f5f5f5;
}

.top-rated-bx {
  margin-top: 15px;
}
.top-rated-bx a {
  display: flex;
  align-items: center;
}
.top-rated-bx a .top-rated-img {
  margin-right: 15px;
  width: 35%;
  box-shadow: 0 0 2px #8a8a8a;
}
.top-rated-bx a .top-rated-img img {
  width: 100%;
}
.top-rated-bx a .top-rated-txt {
  overflow: hidden;
  width: 65%;
}
.top-rated-bx a .top-rated-txt h4 {
  font-size: 14px;
  font-weight: 400;
  color: #4b4b4b;
  margin: 0;
  padding: 0;
}

.contact-title {
  font-size: 20px;
  font-weight: 600;
  color: #b70f0a;
  margin: 0 0 20px;
  padding: 0;
  text-transform: uppercase;
}
.contact-title span {
  color: #000;
}

.rd-mailform textarea.form-control {
  height: 120px;
} /*# sourceMappingURL=style.css.map */

#comments .comments-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin: 10px 0 0;
  padding: 0;
}

#back2Top {
  position: fixed;
  bottom: 88px;
  right: 2px;
  z-index: 8;
}

.contact-page .row {
  gap: 24px 0px;
}
.investor-list{
  position: relative;
  display: block;
  background-color: #FFF;
  border-top: 1px solid #EFF2F7;
  border-bottom: 1px solid #EFF2F7;  
  
}
.investor-anchor{
  display: flex;
  background-color: #FFF;
  align-content: space-around;
  flex-basis: content;
  flex-shrink: inherit;
  padding: 1rem 1.5rem;    
/*  padding-left: 0px !important;*/
  color: #7b7b7b;
  font-weight: bold;

}
.investor-list a:hover{
  color: #b70f0a;
}
.investor-list .active {
  color: #b70f0a;
  background:#fbfbfb;
}
.investor-details{

}
.pdf-item{
  background: #fbfbfb;
  border-top: 1px solid #EFF2F7;
  border-bottom: 1px solid #EFF2F7;
  padding: 0px 28px 28px 28px;
  height: 100% !important;

}
.investor-sub-list {
  
}
@media (max-width: 767px) {
  .main-wrap.contact-page {
    margin-bottom: -30px;
  }
}
@media (max-width: 580px) {
  .main-wrap.contact-page {
    margin-bottom: -20px;
  }
  .inr-ban{
    height: 150px !important;
  }
  .inr-ban .banner-txt h4{
    font-size: 32px;
  }
}
#section {
  word-wrap: break-word;
}
.moretext {
  display: none;
}
.nav-rt-prt ul li a {
  padding: 0px !important;
}
.moreless-button {
  color: #aaa !important;
}

.moretext * strong {
  font-weight: 900;
}
/* Prepayment Calculator */
.input-group-append .input-group-text {
  background-color: #b70f0a;
  border: 1px solid #ced4da;
  color: #fff;
  border-radius: unset;
}
.main-wrap.bannerContact {
  padding-top: 100px;
}
.comment-list > li, .comment-list > li ol li {
  margin-top: 20px;
}
.comment-list > li .comment-metadata {
  margin-top: 8px;
}
.comment-list > li ol li {
  list-style: none;
}
.comment-list li .comment-author b {
  margin-left: 6px;
}
section.bannerSec.innerbanner {
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
}
.tstimonialWrap p {
    font-weight: 500;
    color: #000; 
    margin: 0;
    font-size: 20px;
    letter-spacing: 3px;
}
.custom-nav-products{
    font-size: 19px;
    color: black;
}


/*for bootstrap */

/* Change the background color and text color of the active tab */
.nav-tabs .nav-link.active {
  background-color: #18154E; /* Green background */
  color: white;             /* White text */
/*  border-color: #4CAF50;    /* Border to match the background */
}

/* Optional: Change hover styles for active tab */
.nav-tabs .nav-link.active:hover {
  background-color: #B7110C; /* Slightly darker green */
  color: white;
}

/* Optional: Customize the non-active tab styles */
.nav-tabs .nav-link {
  color: #555; /* Default tab text color */
  border: 1px solid #EAEAEA;
}

.nav-tabs .nav-link:hover {
  color: #B7110C; /* Green text on hover */
}
.border-zero{
  border: 1px solid rgb(255, 255, 255);
}
#investor{
  background-color: #fff;
}
#qlwapp{
  z-index: 8 !important;
}

.menu-dropdown-icon:hover .menu-active {
  display: block;
}
/*.dropdown:hover .dropdown-menu {
  display: block;
}*/
/*.dropdown-menu {
  display: none;
}*/
.menu-active{
  width: 6%;
}

#cf7-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    padding: 30px;
    border-radius: 10px;
    z-index: 9999;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

#cf7-loader .spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-top: 6px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

#cf7-loader p {
    margin-top: 10px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
}

/* Spinner Animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/*############## Responsive ################*/

@media (max-width: 1199.98px) {

	.header .navbar-brand {
		width: 200px;
	}

	.header .navigation-menu ul li a {
		font-size: 14px;
	}

	.header .navigation-menu ul li {
		margin: 0 8px;
	}

	.ms-3 {
		margin-left: 10px !important;
	}

	.ban-inr-sec .banner-txt h3 {
		font-size: 40px;
	}

	.footer .fnav-link li a {
		font-size: 13px;
	}

	.ban-inr-sec .banner-txt h4 {
		font-size: 30px;
	}

	.rt-count-prt .count-bx h3 {
		font-size: 20px;
	}

	.title-prt h2 {
		font-size: 34px;
	}

	.newsletter-prt .newsletter-bx {
		width: 70%;
	}

}

@media (max-width: 991.98px) {

	/*.header {
		position: static;
		padding: 10px 0;
	}*/

	.navbar {
		display: block;
	}

	.navbar .btn-outline-danger {
/*		position: absolute;*/
		left: 133px;
		right: 0px;
		top: 0;
	}

	.menu-mobile {
		margin: 12px 0 0;
		background-color: rgba(0, 0, 0, .7);
		color: #fff;
	}

	.navigation-menu>ul {
		background-color: rgba(0, 0, 0, .9);
	}

	.header .navigation-menu ul li {
		margin: 0;
		background: transparent;
	}

	.header .navigation-menu ul li a {
		padding: 9px 15px;
		border: none;
		border-bottom: 1px solid;
		color: #fff;
	}

	.header .nav-dropdown {
		top: 0;
	}

	.nav-rt-prt ul li a {
		padding: 2px 0 !important;
		border-bottom: none !important;
		color: #020202 !important;
	}

	.menu-mobile, .menu-mobile:hover, .menu-mobile:focus, .menu-mobile:visited {
		color: #fff;
	}

	.ban-inr-sec .banner-txt {
		width: 45%;
	}

	.ban-inr-sec .banner-txt h3 {
		font-size: 35px;
	}

	.ban-inr-sec .banner-txt p {
		margin: 8px 0 0;
		font-size: 14px;
	}

	.about-sec .abt-rt-txt {
		padding: 20px;
	}

	.about-sec .abt-rt-txt h3 {
		font-size: 30px;
	}

	.about-sec .abt-rt-txt p {
		font-size: 15px;
	}

	#horizontalTab ul.resp-tabs-list {
		width: 100%;
	}

	#horizontalTab .resp-tabs-container {
		width: 100%;
	}

	h2.resp-tab-active {
		background: #010101 !important;
		color: #fff !important;
	}

	h2.resp-accordion {
		font-size: 16px;
		color: #B70F0A;
	}

	.calculation-prt ul li label {
		font-size: 13px;
	}

	.title-prt h2 {
		font-size: 30px;
	}

	.title-prt h2 br {
		display: none;
	}

	#verticalTab .resp-tabs-container {
		width: 100%;
	}

	#verticalTab h2.resp-accordion {
		color: #fff;
	}

	.main-title {
		font-size: 32px;
	}

	.newsletter-prt .newsletter-bx {
		width: 80%;
	}

	.mt-5 {
		margin-top: 2rem !important;
	}

	.footer .footer-list2 li {
		width: 50%;
		font-size: 14px;
		margin: 0 0 5px;
	}

	.footer .footer-list li {
		font-size: 14px;
		margin: 0 0 5px;
	}

	.footer .foot-mid .f-pera {
		width: 70%;
		font-size: 15px;
	}

	.ban-inr-sec {
		top: 50%;
	}

	.inr-ban {
		height: 280px;
	}

	.ban-inr-sec .banner-txt h4 {
		font-size: 26px;
		line-height: 38px;
	}

	.mv-section .mv-txt {
		padding: 30px;
	}

	.abt-txt p {
		line-height: 26px;
		font-size: 15px;
	}

	.ban-inr-sec .banner-txt p {
		margin: 0;
	}

	.inr-ban .banner-txt ul {
		margin: 0;
	}

	.inr-ban .banner-txt ul li {
		margin: 0 0 4px;
	}

	.abt-txt h3 {
		font-size: 32px;
	}

	.rt-count-prt {
		padding: 15px;
	}

	.contact-bx ul li {
		width: 100%;
	}
	
	.productDtl-page.inr-ban {
	  min-height: 300px;
	  height: 100%;
	}
	.modal-1 .modal-dialog {
		max-width: 100%;
	}

	.footer {
		margin-bottom: 100px;
	}
	.pro-OverWidth {
		width: 100%;
	}
}

@media (max-width: 767.98px) {
	.navbar .btn-outline-danger {
		display: none;
	}
	.banner-inner-section img {
		height: 210px;
	}
	.p-0 {
		padding: 0 10px !important;
	}
	.ban-inr-sec .banner-txt {
		width: 80%;
		width: 100%;
	}
	.banner-inner-section img {
		height: 340px;
		object-position: 80%;
	}
	.ban-inr-sec {
		position: relative;
		transform: translateY(0%);
		padding: 30px 0;
		background: #fef8f8;
		/* top: 0px; */
	}
	.ban-inr-sec .banner-txt h3 {
		font-size: 19px;
	}
	.ban-inr-sec .banner-txt p {
		margin: 5px 0 0;
		font-size: 13px;
		line-height: 19px;
	}
	.ban-inr-sec .btn-danger {
		padding: 6px 12px !important;
		font-size: 13px;
	}
	.ban-inr-sec .btn-outline-danger {
		padding: 6px 12px !important;
		font-size: 13px;
	}
	.mt-3 {
		margin-top: 10px !important;
	}
	.banner-slider .owl-dots {
		left: inherit;
		right: 10px;
		flex-direction: column;
	}
	.banner-slider .owl-dots .owl-dot {
		margin: 4px 0;
	}
	.about-sec {
		display: block;
	}
	.about-sec .abt-img-prt {
		width: 100%;
	}
	.about-sec .abt-rt-txt {
		padding: 15px;
		width: 100%;
	}
	.about-sec .abt-rt-txt h3 {
		font-size: 26px;
	}
	.btn-light {
		font-size: 14px;
	}
	.btn-outline-light {
		font-size: 14px;
	}
	.d-flex {
		display: block !important;
	}
	.product-wrap {
		padding: 40px 0;
	}
	.pro-OverWidth {
		width: 100%;
	}
	.main-wrap {
		padding: 0px 0;
	}
	.main-title {
		font-size: 28px;
	}
	#horizontalTab ul.resp-tabs-list {
		display: none;
	}
	.calculator-form {
		display: block;
	}
	.calculator-form .form-group {
		width: 100%;
		margin: 8px 0;
	}
	.calculation-prt ul {
		display: block;
	}
	.calculation-prt ul li {
		width: 100%;
		border-right: none;
		border-bottom: 1px solid #C9C9C9;
		padding: 8px 0;
	}
	.title-prt h2 {
		font-size: 20px;
	}
	#verticalTab .resp-tabs-container .resp-tab-content {
		padding: 15px;
	}
	#verticalTab .resp-tabs-container .resp-tab-content .mobile-img {
		width: 50%;
		margin: 0 auto;
	}
	#verticalTab .resp-tabs-container .resp-tab-content .tab-txt {
		width: 100%;
		padding: 15px 0 0;
	}
	.btn-grp img {
		width: 45%;
	}
	.testi-wrap {
		padding: 40px 0;
	}
	.testi-bx {
		padding: 50px 15px 15px;
	}
	.newsletter-prt {
		padding: 20px;
	}
	.main-title {
		font-size: 15px;
	}
	.main-title span {
		font-size: 12px;
	}
	.newsletter-prt .newsletter-bx {
		width: 100%;
	}
	.newsletter-prt .newsletter-bx input[type="email"] {
		font-size: 14px;
		height: 48px;
		padding: 8px 88px 8px 10px;
	}
	.newsletter-prt .newsletter-bx .search-icon {
		font-size: 12px;
		padding: 11px 10px;
	}
	.footer .footer-title {
		margin: 15px 0;
	}
	.footer .footer-list2 li {
		width: 100%;
	}
	.footer .foot-mid {
		display: block;
	}
	.footer .foot-mid .f-pera {
		margin: 12px 0 0;
		width: 100%;
	}
	.footer .f-bttm .f-social {
		text-align: center;
		display: inline-block;
		width: 100%;
	}
	.footer .f-bttm .f-social li {
		float: none;
		display: inline-block;
	}
	.footer .f-bttm .copyright {
		text-align: center;
		margin: 12px 0 0;
	}
	.header .nav-dropdown .nav-img {
		display: none;
	}
	.header .nav-dropdown .nav-rt-prt {
		width: 100%;
		padding: 15px;
		display: block;
	}
	.header .nav-dropdown .nav-rt-prt ul {
		padding: 12px 0 0;
		width: 100%;
	}
	.modal-1 .modal-dialog .modal-content .modal-body {
		padding: 15px;
	}
	.modal .btn-close {
		right: 20px;
		color: #fff;
		z-index: 99999;
		border: 1px solid #fff;
		width: 30px;
		height: 30px;
	}
	.modal-open .modal-1 .modal-dialog {
		margin-top: 110px;
	}
	.modal.show .btn-close {
		top: 26px;
	}
	.ban-inr-sec .banner-txt h4 {
		font-size: 20px;
		line-height: 32px;
	}
	.abt-txt h5 {
		font-size: 14px;
	}
	.abt-txt h3 {
		font-size: 30px;
	}
	.rt-count-prt {
		padding: 20px 20px 0;
	}
	.mv-section {
		display: block;
	}
	.mv-section .mv-img {
		width: 100%;
	}
	.mv-section .mv-txt {
		padding: 15px;
		width: 100%;
	}
	.mt-4 {
		margin-top: 1rem !important;
	}
	.inr-ban .ban-inr-sec .banner-txt {
		width: 100%;
	}
	.inr-ban .banner-txt ul li {
		font-size: 14px;
	}
	.pdf-list li {
		display: block;
		padding: 10px 0;
	}
	.pdf-list li p {
		font-size: 16px;
		margin: 0 0 10px;
	}
	.pdf-list li .btn-outline-danger {
		padding: 6px 15px !important;
		font-size: 14px;
	}
	.contact-img {
		height: 350px;
		margin-bottom: 20px;
	}
	.msme-bx .table tr td {
		padding: 10px 0;
		font-size: 14px;
	}
	.blog-box2 .blog-dtl-txt {
		padding: 15px;
	}
	.category-box {
		margin: 15px 0 0;
	}
	.blog-box2 .blo-img {
		height: auto;
	}
}