@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');


// comon css start
@mixin flexprob {
    display: flex;
    flex-wrap: wrap;
}

@mixin fitimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin positionProb {
    position: relative;
    width: 100%;
}

@mixin maxHeight {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.scale-up-center {
    -webkit-animation: scale-up-center 0.9s ease-in both;
    animation: scale-up-center 0.9s ease-in both;
}

@-webkit-keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

$primaryColor: #B70F0A;
$bg1: #171717;
$bg2: #F7EFEF;
$blackColor: #000;
$whiteColor: #fff;

body {
    font-family: "Manrope", sans-serif;
    color: #5C5C5C;
    margin: 0;
    padding: 0;
    font-size: 15px;
    line-height: 24px;
    background: #fff;
    font-weight: 400;
    overflow-x: hidden;
}

* {
    border: medium none;
    margin: 0;
    padding: 0;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    outline: none !important;
}

a:hover {
    text-decoration: none;
    outline: none;
}

textarea {
    resize: none;
}

p {
    line-height: 25px;
    font-size: 16px;
    font-weight: 300;
    color: #444444;
    margin: 0;
}

/*#######################*/

.main-title {
    color: $blackColor;
    font-size: 40px;
    margin: 0;
    padding: 0;
    font-weight: 800;
    text-align: center;

    span {
        font-size: 16px;
        font-weight: 400;
        color: #989898;
        display: block;
        text-transform: uppercase;
    }
}

.main-pera {
    color: $blackColor;
    text-align: center;
    margin: 15px 0 0;
}

.btn-danger {
    color: $whiteColor;
    background-color: $primaryColor !important;
    border-radius: 4px;
    border: none;
    padding: 8px 20px !important;
    font-weight: 400 !important;
    font-size: 16px;
    position: relative;
    overflow: hidden;

    span {
        color: $whiteColor;
        position: relative;
        transition: all ease-in-out 0.5s;
        z-index: 9;
    }

    &:hover span {
        color: $whiteColor !important;
    }

    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transition: all ease-in-out 0.5s;
        background: $blackColor;
        bottom: -10%;
        left: 0px;
        width: 100%;
        height: 0%;
        border-radius: 50% 50% 0% 0%;
    }

    &:hover::after {
        height: calc(100% + 100%);
    }
}

.btn-light {
    color: $primaryColor;
    background-color: $whiteColor !important;
    border-radius: 4px;
    border: none;
    padding: 8px 20px !important;
    font-weight: 400 !important;
    font-size: 16px;
    position: relative;
    overflow: hidden;

    span {
        color: $primaryColor;
        position: relative;
        transition: all ease-in-out 0.5s;
        z-index: 9;
    }

    &:hover span {
        color: $whiteColor !important;
    }

    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transition: all ease-in-out 0.5s;
        background: $blackColor;
        bottom: -10%;
        left: 0px;
        width: 100%;
        height: 0%;
        border-radius: 50% 50% 0% 0%;
    }

    &:hover::after {
        height: calc(100% + 100%);
    }
}

.btn-outline-danger {
    color: $primaryColor;
    background-color: transparent !important;
    border-radius: 5px;
    border-color: $primaryColor;
    padding: 8px 20px !important;
    font-weight: 400 !important;
    font-size: 16px;
    transition: all ease-in-out 0.3s;
    position: relative;
    overflow: hidden;

    span {
        color: $primaryColor;
        position: relative;
        transition: all ease-in-out 0.5s;
        z-index: 9;
    }

    &:hover span {
        color: $whiteColor !important;
    }

    i {
        margin-right: 5px;
        position: relative;
        z-index: 9;
        transition: all ease-in-out 0.3s;
    }

    &:hover i {
        color: $whiteColor !important;
    }

    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transition: all ease-in-out 0.5s;
        background: $primaryColor;
        bottom: -10%;
        left: 0px;
        width: 100%;
        height: 0%;
        border-radius: 50% 50% 0% 0%;
    }

    &:hover::after {
        height: calc(100% + 100%);
    }
}

.btn-outline-light {
    color: $whiteColor;
    background-color: transparent !important;
    border-radius: 5px;
    border-color: $whiteColor;
    padding: 8px 20px !important;
    font-weight: 400 !important;
    font-size: 16px;
    transition: all ease-in-out 0.3s;
    position: relative;
    overflow: hidden;

    span {
        color: $whiteColor;
        position: relative;
        transition: all ease-in-out 0.5s;
        z-index: 9;
    }

    &:hover span {
        color: $primaryColor !important;
    }

    i {
        margin-right: 5px;
        position: relative;
        z-index: 9;
        transition: all ease-in-out 0.3s;
    }

    &:hover i {
        color: $primaryColor !important;
    }

    &:after {
        content: '';
        position: absolute;
        display: inline-block;
        transition: all ease-in-out 0.5s;
        background: $whiteColor;
        bottom: -10%;
        left: 0px;
        width: 100%;
        height: 0%;
        border-radius: 50% 50% 0% 0%;
    }

    &:hover::after {
        height: calc(100% + 100%);
    }
}

// ####################### 

.header {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 9;
    top: 0;
    padding: 15px 0;

    .navbar-brand {
        width: 225px;
        margin: 0;
    }

    /* Navigation Dropdown Menu --------------------------------*/

    .navigation-menu {
        ul {
            li {
                margin: 0 16px;
                text-transform: capitalize;

                a {
                    color: #444444;
                    font-size: 15px;
                    font-weight: 400;
                    padding: 0;
                    transition: all ease-in-out 0.4s;
                }

                &:hover>a,
                &:focus>a,
                &.active>a {
                    color: #d40000;
                }
            }
        }
    }

    .nav-dropdown {
        width: 100%;
        padding: 0;
        left: 0;
        top: 50px;
        margin: 0 auto;
        border: 1px solid #eee;
        box-shadow: 0 12px 20px rgba(0, 0, 0, .08);
        border-top: 3px solid $primaryColor;

        &:after {
            display: none;
        }

        .nav-img {
            width: 33.33%;

            img {
                @include fitimg();
            }
        }

        .nav-rt-prt {
            width: 66.66%;
            padding: 30px;
            display: flex;
            justify-content: space-between;

            ul {
                padding: 20px 0 0;
                margin: 0;
                border-top: 2px solid $primaryColor;
                width: 48%;

                li {
                    font-size: 15px;
                    color: #444444;
                    margin: 0 0 10px;
                    float: none;
                    font-weight: 400;
                    position: relative;
                    display: flex;
                    align-items: center;
                    transition: all ease-in-out 0.5s;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 0;
                        height: 2px;
                        background-color: $primaryColor;
                        left: 0;
                        transition: all ease-in-out 0.5s;
                    }

                    &:hover {
                        padding-left: 20px;
                    }

                    &:hover:before {
                        width: 15px;
                    }
                }
            }
        }
    }

    .dropworn-arrow {
        top: 8px;
    }

    .navigation-menu ul li.menu-dropdown-icon:hover .nav-dropdown {
        -webkit-animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) .1s both;
        animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) .1s both;
    }

    .navigation-menu ul li.menu-dropdown-icon:hover .dropworn-arrow {
        -webkit-animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) .1s both;
        animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) .1s both;
    }

}


.banner-inner-section {
    position: relative;

    img {
        @include fitimg();
    }
}

.ban-inr-sec {
    position: absolute;
    left: 0;
    right: 0;
    top: 54%;
    transform: translateY(-50%);

    .banner-txt {
        width: 37%;

        h3 {
            font-size: 50px;
            font-weight: 300;
            color: $blackColor;
            margin: 0;
            padding: 0;
        }

        h4 {
            font-size: 33px;
            font-weight: 300;
            color: $blackColor;
            margin: 0;
            padding: 0;
            line-height: 44px;
        }

        p {
            margin: 12px 0 0;
            font-weight: 300;
        }
    }
}


.banner-slider .owl-item.active .banner-txt h3 {
    -webkit-animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
    animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}

.banner-slider .owl-item.active .banner-txt p {
    -webkit-animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s both;
    animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s both;
}

.banner-slider .owl-item.active .banner-txt .btn-grp {
    -webkit-animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
    animation: slide-top 1.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2s both;
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

.banner-slider .owl-dots {
    position: absolute;
    z-index: 9;
    text-align: center;
    bottom: 18px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .owl-dot {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border-radius: 50%;
        margin: 0 4px;
        padding: 2px;

        span {
            border-radius: 50%;
            border: 1px solid #444444;
            display: block;
            width: 100%;
            height: 100%;
        }

        &.active {
            border: 1px solid #444444;
        }

        &.active span {
            background-color: #444444;
        }
    }
}

.about-sec {
    display: flex;
    align-items: stretch;

    .abt-img-prt {
        width: 50%;

        img {
            @include fitimg();
        }
    }

    .abt-rt-txt {
        width: 50%;
        background-color: $primaryColor;
        padding: 50px 120px 50px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5 {
            font-size: 16px;
            font-weight: 300;
            color: $whiteColor;
            margin: 0;
            padding: 0;
        }

        h3 {
            font-size: 38px;
            font-weight: 700;
            color: $whiteColor;
            margin: 0;
            padding: 0;
        }

        p {
            margin: 12px 0 0;
            color: $whiteColor;
        }
    }
}

.product-wrap {
    padding: 60px 0;
    background: $bg1 url(../images/bg2.png) repeat;

    .btn-outline-danger span {
        color: $whiteColor;
    }
}

.title-prt {
    h5 {
        font-size: 16px;
        font-weight: 300;
        color: $whiteColor;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-size: 38px;
        font-weight: 800;
        color: $whiteColor;
        margin: 0;
        padding: 0;
    }
}

.pro-OverWidth {
    width: calc(100% + 15%);
}

.product-box {
    background-color: rgba(255, 255, 255, .03);
    padding: 20px;
    border-radius: 8px;

    .pro-ico {
        display: flex;
        align-items: center;
        height: 60px;

        img {
            max-height: 100%;
            width: auto;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        color: $whiteColor;
        margin: 100px 0 0;
        padding: 0;
    }

    p {
        color: #989898;
        font-size: 14px;
        margin: 12px 0 0;
    }

    a {
        display: inline-block;
        font-size: 16px;
        color: $whiteColor;
        margin: 12px 0 0;
        transition: all ease-in-out 0.4s;

        &:hover {
            color: $primaryColor;
        }
    }
}

.pro-slider .owl-dots {
    position: absolute;
    z-index: 9;
    text-align: center;
    bottom: -30px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .owl-dot {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border-radius: 50%;
        margin: 0 4px;
        padding: 2px;

        span {
            border-radius: 50%;
            border: 1px solid #444444;
            display: block;
            width: 100%;
            height: 100%;
        }

        &.active {
            border: 1px solid #444444;
        }

        &.active span {
            background-color: $whiteColor;
        }
    }
}

.main-wrap {
    padding: 60px 0;
}




h2.resp-accordion {
    color: #217db8;
}

.description-sec .resp-tabs-container {
    background-color: transparent;
    clear: right;
}

#horizontalTab {
    ul.resp-tabs-list {
        position: relative;
        width: 90%;
        margin: 0 auto;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;

        li {
            font-weight: 400;
            font-size: 16px;
            display: inline-block;
            padding: 9px 0;
            margin: 0 25px !important;
            float: none !important;
            color: #989898;
            border: none;
            position: relative;
            overflow: hidden;
            border-radius: 0;
            background-color: transparent;

            &.resp-tab-active {
                color: #000 !important;
                background-color: transparent !important;
                padding: 9px 0 !important;
                font-weight: 800 !important;
                border-bottom: 2px solid $primaryColor !important;
            }
        }
    }

    .description-sec .resp-tab-content {
        border: none;
    }

    .resp-tabs-container {
        background-color: transparent;
        margin-top: 20px;
        width: 90%;
        margin: 20px auto 0;

        .resp-tab-content {
            padding: 20px;
            border: none;
            border-radius: 7px;
            background-color: $bg2;
        }
    }
}

.calculator-form {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-group {
        width: 24%;
        margin: 0;

        .form-control {
            border-radius: 0;
            background-color: $whiteColor;
            height: 40px;
            color: $blackColor;
            font-size: 14px;
            border: none;
        }

        .form-select {
            border-radius: 0;
            background-color: $whiteColor;
            height: 40px;
            color: $blackColor;
            font-size: 14px;
            border: none;
        }
    }
}

.calculation-prt {
    margin: 15px 0 0;

    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
            width: 22%;
            border-right: 1px solid #C9C9C9;

            label {
                font-size: 15px;
                color: #989898;
                font-weight: 400;
            }

            h4 {
                font-size: 20px;
                font-weight: 400;
                color: $blackColor;
                margin: 8px 0 0;
                padding: 0;
            }
        }
    }
}

.information-sec {
    background: $primaryColor url(../images/bg1.png) repeat;
    padding: 60px 0;


    .title-prt {
        h5 {
            color: $whiteColor;
        }

        h3 {
            color: $whiteColor;
        }
    }
}


#verticalTab {
    display: flex !important;
    align-items: stretch;

    .resp-tabs-list {
        background-color: transparent;
        padding: 0;
        border-radius: 10px;
        overflow: hidden;
        width: 25%;
        border: 1px solid #fff;
        margin: 0 30px 0 0;

        li {
            border: none;
            background-color: transparent;
            margin-bottom: 0 !important;
            padding: 15px 15px !important;
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            position: relative;

            &.resp-tab-active {
                background-color: $whiteColor;
                padding: 20px 15px !important;
                color: $blackColor;
                font-weight: 600;
            }
        }
    }

    .resp-tabs-container {
        padding: 0px;
        background-color: rgba(255, 255, 255, .14);
        border: none;
        width: 68%;
        min-height: 250px;
        border-radius: 10px;
        clear: none;

        .resp-tab-content {
            padding: 0;
            position: relative;

            .tab-txt {
                width: 60%;
                padding: 25px 0 25px 25px;

                h3 {
                    font-size: 22px;
                    font-weight: 600;
                    color: $whiteColor;
                    margin: 0;
                    padding: 0;
                    line-height: 32px;
                }

                p {
                    color: $whiteColor;
                    margin: 15px 0 0;
                }

            }

            .mobile-img {
                width: 170px;
            }
        }
    }
}

.testi-wrap {
    padding: 60px 0;
    background: url(../images/bg3.png) repeat;

    .title-prt {
        h5 {
            color: $blackColor;
        }

        h2 {
            color: $blackColor;
        }
    }
}

.testi-bx {
    border-radius: 15px;
    background-color: $whiteColor;
    padding: 50px 25px 25px;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 22px;
        top: 20px;
        background: url(../images/quote.png) no-repeat;
        width: 26px;
        height: 24px;
    }

    p {
        color: $blackColor;
        margin: 0;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        color: $blackColor;
        margin: 15px 0 0;
        padding: 0;
        line-height: 26px;

        span {
            font-size: 16px;
            font-weight: 400;
            color: #989898;
            display: block;
        }
    }
}

.slide .owl-dots {
    position: absolute;
    z-index: 9;
    text-align: center;
    bottom: -55px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .owl-dot {
        width: 15px;
        height: 15px;
        background-color: transparent;
        border-radius: 50%;
        margin: 0 4px;
        padding: 2px;

        span {
            border-radius: 50%;
            border: 1px solid #444444;
            display: block;
            width: 100%;
            height: 100%;
        }

        &.active {
            border: 1px solid #444444;
        }

        &.active span {
            background-color: $blackColor;
        }
    }
}

.footer {
    padding: 60px 0 0;

    .footer-title {
        color: $blackColor;
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 18px;
        padding: 0;
    }

    .footer-list {
        margin: 0;
        padding: 0;

        li {
            float: none;
            font-size: 15px;
            margin: 0 0 7px;
            font-weight: 400;
            padding: 0;

            a {
                color: #989898;
            }
        }
    }

    .footer-list2 {
        margin: 0;
        padding: 0;

        li {
            float: left;
            width: 33.33%;
            font-size: 15px;
            margin: 0 0 7px;
            font-weight: 400;
            padding: 0;

            a {
                color: #989898;
            }
        }
    }

    .fnav-link {
        margin: 0;
        padding: 0;

        li {
            float: none;
            margin-bottom: 15px;

            a {
                color: $blackColor;
                font-size: 15px;
                font-weight: 400;
                overflow: hidden;
                margin: 0;
                transition: all ease-in-out 0.3s;

                &:hover {
                    color: $primaryColor;
                }
            }
        }
    }

    .foot-mid {
        padding: 15px 0;
        margin: 30px 0 0;
        border-top: 1px solid #C9C9C9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .f-logo {
            width: 200px;
        }

        .f-pera {
            color: $blackColor;
            width: 60%;
        }
    }

    .f-bttm {
        background-color: $blackColor;
        padding: 15px 0;

        .f-social {
            margin: 0;
            padding: 0;

            li {
                float: left;
                margin: 0 7px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, .2);
                text-align: center;
                transition: all ease-in-out 0.3s;

                i {
                    font-size: 15px;
                    color: #fff;
                    line-height: 32px;
                }

                &:hover {
                    background-color: $primaryColor;
                }
            }
        }

        .copyright {
            color: #989898;
            font-size: 14px;
            font-weight: 300;
        }
    }
}

.newsletter-prt {
    background: $primaryColor url(../images/bg1.png) no-repeat;
    padding: 45px;
    border-radius: 12px;
    text-align: center;

    .newsletter-bx {
        position: relative;
        margin: 0 auto;
        width: 50%;

        input[type="email"] {
            background-color: rgba(255, 255, 255, .20) !important;
            border: none;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
            height: 52px;
            line-height: 1;
            margin: 0;
            outline: medium none;
            padding: 8px 132px 8px 15px;
            width: 100%;
            border-radius: 6px;
        }

        .search-icon {
            font-size: 17px;
            font-weight: 400;
            padding: 14px 32px;
            color: $primaryColor;
            background-color: $whiteColor;
            display: inline-block;
            border-radius: 0;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 6px 6px 0;
        }
    }
}

.clr-wht {
    color: $whiteColor;

    span {
        color: $whiteColor;
    }
}

// ############## About ###############

.inr-ban {
    position: relative;
    height: 415px;

    img {
        @include fitimg();
    }

    .banner-txt {
        p {
            margin: 6px 0 0;
        }

        ul {
            margin: 8px 0 0;
            padding: 0;

            li {
                font-size: 16px;
                font-weight: 400;
                color: $blackColor;
                margin: 0 0 8px;
                display: flex;
                align-items: center;

                span {
                    color: $primaryColor;
                    margin-right: 4px;
                }
            }
        }
    }
}

.abt-txt {

    h5 {
        font-size: 16px;
        font-weight: 300;
        color: $blackColor;
        margin: 0;
        padding: 0;
    }

    h3 {
        font-size: 38px;
        font-weight: 800;
        color: $blackColor;
        margin: 0;
        padding: 0;
    }

    h4 {
        font-size: 20px;
        font-weight: 600;
        color: $blackColor;
        margin: 25px 0 0;
        padding: 0;
    }

    p {
        margin: 12px 0 0;
        color: $blackColor;
        font-weight: 300;
        line-height: 30px;
    }

    ul {
        margin: 15px 0 0;
        padding: 0;

        li {
            position: relative;
            font-size: 16px;
            font-weight: 400;
            color: $blackColor;
            padding-left: 25px;
            margin: 0 0 10px;
            display: flex;
            align-items: center;

            &::before {
                position: absolute;
                content: "\F271";
                font-family: bootstrap-icons !important;
                font-size: 15px;
                color: $primaryColor;
                left: 0;
            }
        }
    }

    ul.doc-list {
        li {
            &:before {
                content: "\F38B";
                font-family: bootstrap-icons !important;
            }
        }
    }
}

.rt-count-prt {
    background-color: #FFF9F9;
    padding: 30px;
    border-radius: 12px;

    .count-bx {
        margin-bottom: 35px;

        h3 {
            font-size: 28px;
            font-weight: 800;
            color: $blackColor;
            margin: 0;
            padding: 0 0 12px;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                width: 60px;
                height: 3px;
                background-color: $primaryColor;
            }
        }

        p {
            font-size: 16px;
            color: $blackColor;
            margin: 12px 0 0;
        }

        &:last-child {
            margin: 0;
        }
    }
}

.mv-section {
    display: flex;
    align-items: stretch;

    .mv-img {
        width: 35%;

        img {
            @include fitimg();
        }
    }

    .mv-txt {
        width: 65%;
        background-color: $primaryColor;
        padding: 50px 120px 50px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        h4 {
            font-size: 22px;
            font-weight: 600;
            color: $whiteColor;
            margin: 0 0 15px;
            padding: 0;
        }

        p {
            color: #C9C9C9;
            margin-bottom: 15px;
        }
    }
}

.clr-blk {
    h5 {
        color: $blackColor;
    }

    h2 {
        color: $blackColor;
    }
}

.team-bx {
    .team-img {
        border-radius: 6px;
        overflow: hidden;
        height: 250px;

        img {
            @include fitimg();
        }
    }

    h4 {
        font-size: 22px;
        font-weight: 600;
        color: $blackColor;
        margin: 15px 0 0;
        padding: 0;

        span {
            display: block;
            font-size: 15px;
            color: #989898;
        }
    }
}

// ############## Contact ###############

.inr-hdr {
    position: static;
    background: url(../images/bg3.png) repeat;
}

.contact-img {
    height: 458px;
    overflow: hidden;

    img {
        @include fitimg();
    }
}

.form1 {
    margin: 15px 0 0;

    .form-group {
        margin-bottom: 12px;

        .form-control {
            background-color: transparent;
            border-radius: 5px;
            font-size: 14px;
            border: 1px solid #E5E5E5;
            height: 40px;
            font-weight: 300;
            color: #020202 !important;
        }

        textarea.form-control {
            height: 120px;
        }
    }
}

.address-title {
    font-size: 16px;
    font-weight: 600;
    color: #989898;
    margin: 0;
    padding: 8px 0;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 35px;
        height: 3px;
        background-color: $primaryColor;
    }
}

.f-contact {
    margin: 10px 0 0;
    padding: 0;
    display: inline-block;

    li {
        float: none;
        margin-bottom: 12px;
        display: flex;
        align-items: baseline;

        i {
            color: $primaryColor;
            margin-right: 6px;
            font-size: 17px;
            width: 26px;
        }

        p {
            color: $blackColor;
            margin: 0;
            font-weight: 300;
            transition: all ease-in-out 0.3s;
        }

        a {
            color: $blackColor;
            font-size: 15px;
            font-weight: 300;
            transition: all ease-in-out 0.3s;
        }

        &:hover a {
            color: $primaryColor;
        }
    }
}

.contact-bx {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E8D5D5;

    ul {
        li {
            float: left;
            width: 33.33%;

            &:first-child {
                width: 100%;
            }
        }
    }

    &:last-child {
        border: none;
        padding: 0;
        margin: 0;
    }
}

.media-bx {
    .media-img {
        overflow: hidden;
        border-radius: 8px;
        height: 270px;
        display: flex;
        align-items: center;
        text-align: center;
        position: relative;

        img {
            @include fitimg();
        }

        .play-btn {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;

            i {
                font-size: 52px;
                color: $primaryColor;
            }
        }
    }

    .date {
        font-size: 15px;
        font-weight: 400;
        color: #989898;
        margin: 8px 0 0;
        display: block;
    }

    h4 {
        font-size: 17px;
        font-weight: 600;
        color: $blackColor;
        margin: 8px 0 0;
        padding: 0;
        height: 43px;
        @include maxHeight();
    }

    a {
        font-size: 17px;
        font-weight: 500;
        color: $primaryColor;
        display: inline-block;
    }
}

.press-wrap {
    background-color: #FFF9F9;
    padding: 55px 0;
}

.press-bx {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #E8D5D5;

    h3 {
        font-size: 18px;
        font-weight: 500;
        color: $blackColor;
        margin: 6px 0 0;
        padding: 0;
    }

    p {
        color: #444444;
        margin: 8px 0 0;
    }

    a {
        font-size: 17px;
        font-weight: 500;
        color: $primaryColor;
        display: inline-block;
    }
}

.quick-bx {
    margin-bottom: 20px;

    .q-ico {
        display: flex;
        align-items: center;
        height: 35px;

        img {
            max-height: 100%;
        }
    }

    h4 {
        font-size: 20px !important;
        font-weight: 600;
        color: $whiteColor;
        margin: 8px 0 0 !important;
        padding: 0;
    }

    p {
        color: $whiteColor;
        font-size: 15px;
        margin: 6px 0 0;
    }
}

.msme-bx {
    h3 {
        font-size: 22px;
        font-weight: 600;
        color: $blackColor;
        margin: 0;
        padding: 10px 0 0;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 35px;
            height: 4px;
            background-color: #B70F0A;
        }
    }

    p {
        color: #727070;
        font-size: 15px;
        line-height: 27px;
        margin: 8px 0 0;
    }

    .table tr td {
        padding: 15px 0;
        font-size: 16px;
        color: $blackColor;
        font-weight: 500;
    }

    .benifit-bx {
        margin: 20px 0 0;

        h4 {
            font-size: 18px;
            font-weight: 600;
            color: $blackColor;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;

            span {
                color: $primaryColor;
                margin-right: 4px;
            }
        }
    }
}

.pdf-list {
    margin: 0;
    padding: 0;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;
        border-bottom: 1px solid #E3E3E3;

        p {
            font-size: 18px;
            font-weight: 500;
            color: $blackColor;
            margin: 0;

            img {
                margin-right: 8px;
            }
        }
    }
}

/* ######### Apply Now Popup ########## */

.modal .btn-close {
    position: absolute;
    top: 0;
    right: 50px;
    color: #fff;
    z-index: 9;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    background: transparent;
    border: 1px solid #fff;
    opacity: 0;
    transition: 0.5s;
    padding: 0;
}

.modal.show .btn-close {
    transition-delay: 0.7s;
    opacity: 1;
    top: 50px;
}

.modal::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.87);
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
    transform: scale(0.04) translateY(9999px);
    overflow: hidden;
    z-index: 9;
}

.modal-1 .modal-dialog {
    max-width: 400px;
    z-index: 9999;
    top: 0 !important;
    transform: translateY(-50%);
    transition: all 0.55s ease, opacity 0.35s ease-in;
    opacity: 0;
    z-index: 9999;

    .modal-content {
        border-radius: 12px;

        .modal-body {
            padding: 25px;

            .form1 {
                label {
                    font-size: 15px;
                    color: #444444;
                    margin-bottom: 4px;
                }

                p {
                    color: #444444;
                    font-size: 15px;

                    span {
                        color: $primaryColor;
                    }
                }
            }
        }
    }
}

.modal-1.fade.show .modal-dialog {
    transform: translateY(0);
    transition-delay: 0.7s;
    opacity: 1;
    top: 50% !important;
    transform: translateY(-50%);
}

.modal-1.fade .modal-dialog {
    transition-delay: 0.7s;
    transition: opacity 0.7s;
}

.modal-1.fade.show::before {
    animation: menu-animation 0.8s ease-out forwards;
    transform: scale(2) translateY(0);
}

.modal-open .modal-1 {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

@keyframes menu-animation {
    0% {
        opacity: 0;
        transform: scale(0.04) translateY(300%);
    }

    40% {
        transform: scale(0.04) translateY(0);
        transition: ease-out;
    }

    40% {
        transform: scale(0.04) translateY(0);
    }

    60% {
        opacity: 1;
        transform: scale(0.02) translateY(0px);
    }

    61% {
        opacity: 1;
        transform: scale(0.04) translateY(0px);
    }

    99% {
        opacity: 1;
        height: 0;
        padding-bottom: 100%;
        border-radius: 100%;
    }

    100% {
        opacity: 1;
        transform: scale(2) translateY(0px);
        height: 100%;
        padding-bottom: 0;
        border-radius: 0;
    }
}

/*######### Blog-details ##########*/

.blog-box2 {
    margin-bottom: 20px;
    border-radius: 10px;

    .blo-img {
        height: 350px;
        overflow: hidden;

        img {
            @include fitimg();
        }
    }

    .blog-dtl-txt {
        padding: 25px;
        border: 1px solid #ccc;

        img {
            margin: 12px 0;
        }

        .date-pagi {
            margin: 0;
            padding: 0;
            display: inline-flex;

            li {
                float: left;
                margin: 0 12px 0 0;
                padding: 0 12px 0 0;
                font-size: 15px;
                font-weight: 300;
                color: #000;
                border-right: 1px solid #D7D7D7;

                i {
                    margin-right: 8px;
                    color: $primaryColor;
                }
            }
        }

        h3 {
            font-size: 24px;
            font-weight: 700;
            color: $blackColor;
            margin: 10px 0 0;
            padding: 0;
        }

        h4 {
            font-size: 18px;
            color: $primaryColor;
            margin: 15px 0;
            padding: 0;
        }

        p {
            font-size: 15px;
            color: #525252;
            margin: 15px 0 0;
            line-height: 24px;
        }
    }
}

.blog-footr-prt {
    margin: 15px 0 0;

    .blog-social {
        margin: 0;
        padding: 0;
        display: inline-block;

        li {
            float: left;
            margin-right: 15px;
            font-size: 17px;

            i {
                color: #A0A0A0;
                transition: all ease-in-out 0.3s;

                &:hover {
                    color: $primaryColor;
                }
            }
        }
    }
}

.category-box h3 {
    font-size: 18px;
    color: $primaryColor;
    font-weight: 600;
    text-transform: uppercase;
    padding: 8px 12px;
    margin: 0;
    background-color: #F5F5F5;
}

.top-rated-bx {
    margin-top: 15px;

    a {
        display: flex;
        align-items: center;

        .top-rated-img {
            margin-right: 15px;
            width: 35%;
            box-shadow: 0 0 2px #8A8A8A;

            img {
                width: 100%;
            }
        }

        .top-rated-txt {
            overflow: hidden;
            width: 65%;

            h4 {
                font-size: 14px;
                font-weight: 400;
                color: #4B4B4B;
                margin: 0;
                padding: 0;
            }
        }

    }
}

.contact-title {
    font-size: 20px;
    font-weight: 600;
    color: $primaryColor;
    margin: 0 0 20px;
    padding: 0;
    text-transform: uppercase;

    span {
        color: #000;
    }
}

.rd-mailform textarea.form-control {
    height: 120px;
}