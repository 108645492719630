.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
  }
  
  .slick-list {
	position: relative;
	/* overflow: hidden; */
	display: block;
	margin: 0;
	padding: 0;
  }
  
  .slick-track {
	position: relative;
	width: 200px;
	display: flex;
	flex-direction: row; /* Ensures slides align properly */
	top: 0;
	left: 0;
  }
  
  .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
  }
  
  .slick-slide img {
	display: block;
	width: 100%;
  }
  
  .slick-initialized .slick-slide {
	display: block;
  }
  
  .slick-dots {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .slick-dots li {
	position: relative;
	display: inline-block;
	margin: 0 5px;
	border-radius: 50%;
  }
  
  .slick-dots button {
	font-size: 0;
	line-height: 0;
	display: block;
	width: 4px;
	height: 4px;
	padding: 5px;
	cursor: pointer;
	border: 1px	solid #ccc;
	outline: none;
	background: #ccc;
	border-radius: 50%;
  }
  
  .slick-dots li.slick-active button {
	background-color: #c31001;
	font-size:0;
  }
  
  /* Navigation Arrows */
  .slick-prev,
  .slick-next {
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 30px;
	height: 30px;
	margin-top: -15px;
	padding: 0;
	cursor: pointer;
	border: 2px solid #ccc;
	outline: none;
	background: transparent;
	color: #333;
	z-index: 1;
  }
  
  .slick-prev {
	left: -35px;
  }
  
  .slick-next {
	right: -35px;
  }
  .slick-dots li button:before{
	border: 2px solid #ccc;
	border-radius: 50%;
    font-size: 0;
    
  }
  .slick-prev::before,
  .slick-next::before {
	font-family: "slick";
	font-size: 20px;
	line-height: 1;
	opacity: 0.75;
	color: #333;
	border: 2px solid #ccc;
  }
  
  .slick-prev::before {
	content: "←";
  }
  
  .slick-next::before {
	content: "→";
  }